import React, { useState } from 'react';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Button, InputAdornment, TextField } from '@mui/material';

import { FiMail, FiUser } from 'react-icons/fi';

import { makeStyles, useTheme } from '@mui/styles';

export default function ForgetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    const [state, setState] = useState({
        email: '',
        username: ''
    });
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        postUrl('/forgot-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box className="min-h-100vh pos-relative flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
            <div className='flex-c-m w-full' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/background/background.png")', backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
                <img src="/logo_text.png" className='m-b-84' style={{ height: '100px', width: 'auto' }} alt="logo" />
            </div>
            
            <div className='textfield-border' style={{ marginTop: -47, }}>
                <div className=' pos-relative textfield-bkg txt-white' >
                    <div className='p-t-50'>
                        <p className='fs-21'>{t('login.forgotPassword')}</p>
                        <p className='fs-14 p-t-5' style={{ color: theme.palette.textColor }}>{t('forgotPassword.desc')}</p>
                    </div>
                    <div>
                        <div className='w-full'>
                            {/* <div><Typography>{t('user.email')}</Typography></div> */}
                            <div className='w-full'>
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.email')}
                                    fullWidth
                                    type="email"
                                    autoComplete="email"
                                    color='primary'
                                    value={state.email}
                                    onChange={({ target }) => setState({ ...state, email: target.value })}
                                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                    error={inputErrors && inputErrors.email ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='w-full'>
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.username')}
                                    fullWidth
                                    type="username"
                                    autoComplete="username"
                                    color='primary'
                                    value={state.username}
                                    onChange={({ target }) => setState({ ...state, username: target.value })}
                                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                    error={inputErrors && inputErrors.username ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiUser className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className='p-t-30'>
                            <Button variant="contained" color="primary" size="large" onClick={resetPassword} fullWidth><p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.reset')}</p></Button>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop100: {
        marginTop: 100,
        padding: '0px 10% 0px 10%'
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        letterSpacing: '-0.3px',
        lineHeight: '130%'
    },
    desc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '125%'
    }
}));