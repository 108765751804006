import {
    STORE_PROFILE,
    RESET_PROFILE,
    STORE_PLAYER_ID,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    username: '',
    email: '',
    status: '',
    two_factor_confirmed_at: '',
    crypto_address: '',
    email_verified_at: '',
    player_id: '',
    sub_email: '',
    web_three: 0,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return {
                ...state,
                id: actions.payload.id,
                username: actions.payload.username,
                email: actions.payload.email,
                status: actions.payload.status,
                two_factor_confirmed_at: actions.payload.two_factor_confirmed_at,
                crypto_address: actions.payload.crypto_address,
                email_verified_at: actions.payload.email_verified_at,
                player_id: actions.payload.player_id,
                sub_email: actions.payload.sub_email,
                web_three: actions.payload.web_three
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        case STORE_PLAYER_ID:
            return {
                ...state,
                player_id: actions.payload
            };
        default:
            return state;
    }
}