import { useEffect, useState, useRef, useCallback } from 'react';
import { Button, CircularProgress, Divider, Drawer, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import { currencyFormat, truncate } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WalletConnect } from '@layouts/WalletConnect';

import DoneIcon from '@mui/icons-material/Done';
import { FaArrowDown, FaArrowRight } from "react-icons/fa6";
import { GoChevronDown } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { FiX, FiAlertCircle, FiCopy, FiCheck } from "react-icons/fi";


import { QRCodeSVG } from 'qrcode.react';

const SWAP_SETTING = {
    'usdt': 'Tether USD',
    'jfa': 'JFA Token',
    'jfu': 'JFU Token',
    'jfg': 'JFG Token'
}

const SWAP_CONTRACT_TYPE = {
    'usdt-jfa': 'jfa',
    'jfu-usdt': 'jfu',
    'jfu-jfa': 'jfa',
    'usdt-jfu': 'usdt' // approve in usdt contract but swap in jfu contract
}

export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { username, email, crypto_address, web_three } = useSelector(state => state.user);
    const { ABI, chain, chainId, provider, hexChainId } = WalletConnect();
    const { walletProvider } = useWeb3ModalProvider();

    const [swapTypes, setSwapTypes] = useState([]);
    const [info, setInfo] = useState({
        // companyAddress: '',
        contractAddress: [],
        balances: [],
        allowances: []
    });
    const [state, setState] = useState({
        pay: '',
        receive: 0,
        swap: 'usdt-jfa',
        txid: '',
    })
    const [firstPart, secondPart] = (state.swap).split('-');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [swapDrawerOpen, setSwapDrawerOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [swapReview, setSwapReview] = useState({
        approve: false,
        approveRetry: false,
        approveLoading: true,
        swap: false,
        swapRetry: false,
        swapLoading: true
    });
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [stepper, setStepper] = useState(1);
    const [counter, setCounter] = useState({
        approve: 0,
        swap: 0
    })
    const explorerLink = process.env.NODE_ENV == 'development' ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/';

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'pay') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handlePair = swapType => {
        setState({ ...state, swap: swapType });
        setDrawerOpen(false);
    }

    useEffect(() => {
        let finalAmount = 0;
        let rate = swapTypes[state.swap]?.rate || 0;
        if (state.swap == 'jfu-usdt') {
            finalAmount = state.pay < swapTypes[state.swap]?.minimum_charge_amount ? (state.pay - swapTypes[state.swap]?.minimum_charge) : (state.pay - (currencyFormat(state.pay * swapTypes[state.swap]?.fee_percent / 100, 2)));
        } else {
            finalAmount = state.pay;
        }
        finalAmount *= rate;
        setState({ ...state, receive: finalAmount < 0 ? 0 : finalAmount });
        if (Number(state.pay || 0) > Number(info.balances[firstPart] || 0) || state.pay < swapTypes[state.swap]?.minimum) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
        // eslint-disable-next-line
    }, [state.swap, state.pay, swapTypes, info])

    useEffect(() => {
        setLoading(true);
        getUrl('/wallets/swap-setting').then(response => {
            if (response.status) {
                setSwapTypes(response.data?.swap_setting);
                setInfo({
                    ...info,
                    // companyAddress: response.data?.company_address,
                    contractAddress: response.data?.contract_address,
                    balances: response.data?.balances,
                    // allowances: response.data?.allowances
                });
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [accessToken, refresh])

    const approve = async (contractAddress, abi) => {
        setSwapReview({ ...swapReview, approveLoading: true, approveRetry: false, approve: false });
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider /* && info.companyAddress */) {
            const getAllowance = async () => {
                try {
                    const response = await getUrl(`/wallets/allowance/${state.swap}`);
                    if (response.status) {
                        if (Number(state.pay) <= Number(response.data)) {
                            setStepper(3);
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        setSwapReview({ ...swapReview, approveLoading: false, approveRetry: true });
                        addAlert('', response.message || t('error.contactSupport'), 'error', '');
                        return true;
                    }
                } catch (error) {
                    setSwapReview({ ...swapReview, approveLoading: false, approveRetry: true });
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    return true;
                };
            }
            const allowanceResponse = await getAllowance();
            if (allowanceResponse) return;  // if true no run approve, failed to get allowance also go true
            // change chain if not connected to correct chain
            if (chain['id'] != chainId) {
                try {
                    await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                } catch (error) {
                    setSwapReview({ ...swapReview, approveLoading: false, approveRetry: true });
                    addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                    return;
                }
            }
            try {
                let signer = await provider.getSigner();
                let contract = new ethers.Contract(contractAddress, abi, signer);
                let tx;
                if (state.swap == 'usdt-jfu') {
                    tx = await contract.approve(info.contractAddress['jfu'], ethers.MaxUint256);
                } else {
                    tx = await contract.approve(info.contractAddress[SWAP_CONTRACT_TYPE[state.swap]], ethers.MaxUint256);
                }
                let receipt = await provider.waitForTransaction(tx.hash);
                if (receipt.status) {
                    // console.log("contract approve Success");
                    // setRefresh(!refresh);
                    setSwapReview({ ...swapReview, approveLoading: false, approve: true });
                    setStepper(3); // proceed swap
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                    // console.log("contract approve Failed", receipt);
                    setSwapReview({ ...swapReview, approveLoading: false, approve: false, approveRetry: true });
                }
            } catch (error) {
                const revertReason = (error.reason || error.message) || t('error.transactionError');
                addAlert('', revertReason, 'error', '');
                setSwapReview({ ...swapReview, approveLoading: false, approve: false, approveRetry: true });
                // addAlert('', error.message || t('error.contactSupport'), 'error', '');
                // console.log("contract approve Failed", error);
            }
        } else {
            setSwapReview({ ...swapReview, approveLoading: false, approveRetry: true });
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    const swap = async (type, contractAddress, abi) => {
        setSwapReview({ ...swapReview, swapLoading: true, swapRetry: false, swap: false });
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider) {
            // change chain if not connected to correct chain
            if (chain['id'] != chainId) {
                try {
                    await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                } catch (error) {
                    setSwapReview({ ...swapReview, swapLoading: false, swapRetry: true, swap: false });
                    addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                    return;
                }
            }
            try {
                let signer = await provider.getSigner();
                let contract = new ethers.Contract(contractAddress, abi, signer);
                let tx;
                switch (type) {
                    case 'usdt-jfa':
                        tx = await contract.swap(ethers.parseUnits(state.pay));
                        break;
                    case 'jfu-jfa':
                        tx = await contract.jfuSwapJfa(ethers.parseUnits(state.pay));
                        break;
                    case 'jfu-usdt':
                        tx = await contract.withdrawUSDT(ethers.parseUnits(state.pay));
                        break;
                    case 'usdt-jfu':
                        contract = new ethers.Contract(info.contractAddress['jfu'], ABI['jfu'], signer);
                        tx = await contract.swap(ethers.parseUnits(state.pay));
                        break;
                    default:
                        addAlert('', t('error.contactSupport'), 'error', '');
                        return;
                }
                if (tx) {
                    setSwapReview({ ...swapReview, swapLoading: false });
                    setState({ ...state, txid: tx.hash });
                    setStepper(4);
                }
                let receipt = await provider.waitForTransaction(tx.hash);
                if (receipt.status) {
                    // console.log("contract swap Success");
                    setRefresh(!refresh);
                    setStepper(5);
                } else {
                    setSwapReview({ ...swapReview, swapLoading: false, swapRetry: true, swap: false });
                    addAlert('', t('error.contactSupport'), 'error', '');
                    // console.log("contract swap Failed", receipt);
                }
                setSwapReview({ ...swapReview, swapLoading: false, swap: true, swapRetry: false });
            } catch (error) {
                setSwapReview({ ...swapReview, swapLoading: false, swap: false, swapRetry: true });
                const revertReason = error.reason || error.message || t('error.transactionError');
                addAlert('', revertReason + '. ' + t('error.contactSupport'), 'error', '');
                // console.log("contract swap Failed", error);
            }
        } else {
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    /* web2 deposit withdrawal */
    // useEffect(() => {
    //     setLoading(true);
    //     getUrl('/wallets/deposit_address').then(response => {
    //         if (response.status) {
    //             setState({ ...state, address: response.data.bicpay, rules: response.rules });
    //         }else{
    //             addAlert('', t('error.contactSupport'), 'error', '');
    //         }
    //     }).catch(error => {
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     }).finally(() => {
    //         setLoading(false);
    //     });
    //     // eslint-disable-next-line
    // }, []);
    /*  */

    useEffect(() => {
        // reset stepper and data
        if (!swapDrawerOpen) {
            setState({ ...state, pay: '', receive: 0, txid: '' });
            setSwapReview({
                ...swapReview,
                approve: false,
                approveRetry: false,
                approveLoading: true,
                swap: false,
                swapRetry: false,
                swapLoading: true
            })
            setStepper(1);
            setCounter({ ...counter, approve: 0, swap: 0 });
        }
        // eslint-disable-next-line
    }, [swapDrawerOpen])

    const reviewSwapStepper = (step) => {
        switch (step) {
            case 1: // show rate
                return (
                    <div className='p-t-50'>
                        <div className='flex-sb-m fs-12'>
                            <p style={{ color: theme.palette.textColor }}>{t('swaps.rate')}</p>
                            <p className='clwhite txt-upper'>1 {firstPart} = {currencyFormat(1 * (swapTypes[state.swap]?.['rate'] || 0), 2)} {secondPart}</p>
                        </div>
                        {
                            state.swap == 'jfu-usdt' &&
                            <div className='flex-sb-m p-t-10 fs-12' style={{ color: theme.palette.textColor }}>
                                <p>{t('withdraw.fee')}</p>
                                <div className='txt-right'>
                                    <p style={state.pay < swapTypes[state.swap]?.minimum_charge_amount ? { color: '#FFFFFF' } : null}>{"Less Than $" + swapTypes[state.swap]?.minimum_charge_amount + " = " + swapTypes[state.swap]?.minimum_charge + " USDT"}</p>
                                    <p className='p-t-5' style={state.pay >= swapTypes[state.swap]?.minimum_charge_amount ? { color: '#FFFFFF' } : null}>{"Atleast $" + swapTypes[state.swap]?.minimum_charge_amount + " = " + swapTypes[state.swap]?.fee_percent + "% USDT"}</p>
                                </div>
                            </div>
                        }
                        <div className='p-t-20'>
                            <Button disabled={insufficientBalance || state.pay <= 0} className='w-full' style={{ color: theme.palette.primary.main, borderRadius: 16, minHeight: 56 }} onClick={() => setStepper(2)} ><p className='flex-m txt-title fs-18'>{t('swaps.acceptSwap')}</p></Button>
                        </div>
                    </div>
                );
            case 2: // approve wallet progress
            case 3: // sign msg
            case 4: // wait confirmation
                if (step == 2 && counter.approve < 2) {
                    setCounter({ ...counter, approve: counter.approve + 1 })
                    approve(info.contractAddress[firstPart], ABI[firstPart]);
                }
                if (step == 3 && counter.swap < 2) {
                    setCounter({ ...counter, swap: counter.swap + 1 })
                    swap(state.swap, info.contractAddress[SWAP_CONTRACT_TYPE[state.swap]], ABI[SWAP_CONTRACT_TYPE[state.swap]]);
                }
                return (
                    <div className='p-t-50 p-b-20'>
                        <div className='flex-col fs-16' style={{ gap: 10 }}>
                            <div className='flex-sb-m'>
                                <div className='flex-l-m'>
                                    <div className='p-all-6' style={{ background: step == 2 ? theme.palette.primary.main : theme.palette.textColor, borderRadius: 100, height: 32, width: 32 }}>
                                        <img src='/images/general/approve-swap.png' alt='approve' style={{ width: 21, height: 21 }} />
                                    </div>
                                    <p className='m-l-10' style={{ color: step == 2 ? '#FFFFFF' : theme.palette.textColor }}>{t('swaps.approveMsg')}</p>
                                </div>
                                <div>
                                    {
                                        swapReview.approveLoading ?
                                            <CircularProgress style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                            :
                                            <>
                                                {
                                                    swapReview.approve ?
                                                        <DoneIcon style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                                        :
                                                        <p className='pointer txt-upper' style={{ color: theme.palette.primary.main }} onClick={() => approve(info.contractAddress[firstPart], ABI[firstPart])}>{t('button.retry')}</p>

                                                }
                                            </>
                                    }
                                </div>
                            </div>
                            <Divider style={{ transform: 'rotate(90deg)', width: 16, color: theme.palette.textColor, border: `1px solid ${theme.palette.textColor}`, marginLeft: 7, marginTop: 8, marginBottom: 8 }} />
                            <div className='flex-sb-m'>
                                <div className='flex-l-m'>
                                    <div className='p-all-6' style={{ background: step == 3 ? theme.palette.primary.main : theme.palette.textColor, borderRadius: 100, height: 32, width: 32 }}>
                                        <img src='/images/general/sign-swap.png' alt='sign' style={{ width: 22, height: 21 }} />
                                    </div>
                                    <p className='m-l-10' style={{ color: step == 3 ? '#FFFFFF' : theme.palette.textColor }}>{t('swaps.signMsg')}</p>
                                </div>
                                <div>
                                    {
                                        step >= 3 &&
                                        <>
                                            {
                                                swapReview.swapLoading ?
                                                    <CircularProgress style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                                    :
                                                    <>
                                                        {
                                                            state.txid ?
                                                                <DoneIcon style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                                                :
                                                                <p className='pointer txt-upper' style={{ color: theme.palette.primary.main }} onClick={() => swap(state.swap, info.contractAddress[SWAP_CONTRACT_TYPE[state.swap]], ABI[SWAP_CONTRACT_TYPE[state.swap]])}>{t('button.retry')}</p>
                                                        }
                                                    </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <Divider style={{ transform: 'rotate(90deg)', width: 16, color: theme.palette.textColor, border: `1px solid ${theme.palette.textColor}`, marginLeft: 7, marginTop: 8, marginBottom: 8 }} />
                            <div className='flex-sb-m'>
                                <div className='flex-l-m'>
                                    <div className='p-all-6' style={{ background: step == 4 ? theme.palette.primary.main : theme.palette.textColor, borderRadius: 100, height: 32, width: 32 }}>
                                        <img src='/images/general/confirm-swap.png' alt='confirm' style={{ width: 24, height: 19 }} />
                                    </div>
                                    <p className='m-l-10' style={{ color: step == 4 ? '#FFFFFF' : theme.palette.textColor }}>{t('swaps.confirmMsg')}</p>
                                </div>
                                {
                                    step == 4 &&
                                    <CircularProgress style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                }
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }


    return (
        <div className='p-t-120 p-lr-20' style={{ minHeight: '100vh' }}>
            <div className='p-t-20 clwhite flex-c-m'>
                {
                    state.swap &&
                    <div className='flex-sa-m bor10 pointer' style={{ background: '#1B1B1B', padding: '6px 14px 6px 6px' }} onClick={() => setDrawerOpen(true)}>
                        <div className='flex-m p-l-10'>
                            <img src={`/images/wallet/token-${firstPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                            <p className='txt-upper clwhite fs-14 m-l-5'>{firstPart}</p>
                        </div>
                        <FaArrowRight className='fs-14 m-lr-10' />
                        <div className='flex-m'>
                            <img src={`/images/wallet/token-${secondPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                            <p className='txt-upper clwhite fs-14 m-l-5'>{secondPart}</p>
                        </div>
                        <GoChevronDown className='m-l-10' style={{ color: theme.palette.textColor }} />
                    </div>
                }
            </div>
            <div className='p-t-15 flex-col-c'>
                <div className="w-full flex-col p-r-20 p-t-20 p-b-10" style={{ background: '#1B1B1B', borderRadius: 16, minHeight: 94, marginBottom: '-17px' }}>
                    <div className='flex-sb-m p-l-20 fs-12' style={{ color: insufficientBalance ? '#c80000' : theme.palette.textColor }}>
                        <p className='txt-title'>{t('swaps.pay')}</p>
                        <p className='txt-title'>{t('swaps.balance')} {currencyFormat(info.balances[firstPart] || 0, 2)}</p>
                    </div>
                    <div className='w-full p-t-10'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='pay'
                            type="number"
                            placeholder="0"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            value={state.pay}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end" className='flex-col-m'>
                                        <div className='flex-r-m'>
                                            <img src={`/images/wallet/token-${firstPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                                            <p className='txt-upper clwhite fs-14 m-l-5'>{firstPart}</p>
                                        </div>
                                        {/* {
                                            (approveRequired && !insufficientBalance) &&
                                            <p className='fs-14 m-t-5 pointer' style={{ color: theme.palette.primary.main }} onClick={() => approve(info.contractAddress[firstPart], ABI[firstPart])}>{t('swaps.approve')}</p>
                                        } */}
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                input: {
                                    fontSize: '30px',
                                    color: insufficientBalance ? '#c80000' : '#ffffff'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex-c-m" style={{ zIndex: 3, background: '#1B1B1B', borderRadius: 6, border: '4px solid #121212', gap: 10, color: '#ffffff' }}><FaArrowDown className='fs-16 m-all-8' /></div>
                <div className="w-full flex-col p-all-20" style={{ background: '#1B1B1B', borderRadius: 16, minHeight: 94, marginTop: '-17px' }}>
                    <div className='flex-sb-m fs-12' style={{ color: theme.palette.textColor }}>
                        <p className='txt-title'>{t('swaps.get')}</p>
                        <p className='txt-title'>{t('swaps.balance')} {currencyFormat(info.balances[secondPart] || 0, 2)}</p>
                    </div>
                    <div className='flex-sb-m p-t-20'>
                        <p className='fs-30' style={{ color: state.receive > 0 ? '#FFFFFF' : '#5E5E5E' }}>{currencyFormat(state.receive || 0, 2)}</p>
                        <div className='flex-m'>
                            <img src={`/images/wallet/token-${secondPart}.png`} alt="receive-token" style={{ width: 20, aspectRatio: 1 }} />
                            <p className='txt-upper clwhite fs-14 m-l-5'>{secondPart}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                state.swap == 'jfu-usdt' &&
                <div className='flex-sb-m p-t-20 p-lr-10 ' style={{ color: theme.palette.textColor }}>
                    <p className='clwhite'>{t('withdraw.fee')}</p>
                    <div className='txt-right fs-16'>
                        <p className='fs-12' style={state.pay < swapTypes[state.swap]?.minimum_charge_amount ? { color: '#FFFFFF' } : null}>{"Less Than $" + swapTypes[state.swap]?.minimum_charge_amount + " = " + swapTypes[state.swap]?.minimum_charge + " USDT"}</p>
                        <p className='p-t-5 fs-12' style={state.pay >= swapTypes[state.swap]?.minimum_charge_amount ? { color: '#FFFFFF' } : null}>{"Atleast $" + swapTypes[state.swap]?.minimum_charge_amount + " = " + swapTypes[state.swap]?.fee_percent + "% USDT"}</p>
                    </div>
                </div>
            }
            {
                web_three &&
                <div className='p-t-20'>
                    {
                        !accessToken ?
                            <Button className='w-full' style={{ color: theme.palette.primary.main, borderRadius: 16, minHeight: 56 }}><p className='flex-m txt-title fs-18'>{t('general.connectWallet')}</p></Button>
                            :
                            <Button variant='contained' fullWidth disabled={insufficientBalance || state.pay <= 0} onClick={() => setSwapDrawerOpen(true)} ><p >{t('swaps.swapNow')}</p></Button>
                    }
                </div>
            }
            {/* swap list drawer */}
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center' }}>
                    <div className='flex-sb-m'>
                        <p className='clwhite fs-16'>{t('swaps.selectPair')}</p>
                        <MdClose className='pointer' style={{ width: 24, height: 24 }} onClick={() => setDrawerOpen(false)} />
                    </div>
                    <div className='p-t-20 p-b-50'>
                        {
                            _.map(swapTypes, (data, key) => {
                                let [from, to] = (key).split('-');
                                return (
                                    <div key={key} className='flex-sa-m m-b-10 bor10 pointer' style={{ border: `1px solid ${key == state.swap ? '#5E5E5E' : '#242424'}`, padding: '10px 16px 10px 16px' }} onClick={() => handlePair(key)}>
                                        <div className='flex-m'>
                                            <img src={`/images/wallet/token-${from}.png`} alt="pay-token" style={{ width: 32, aspectRatio: 1 }} />
                                            <div className='flex-col-l m-l-10'>
                                                <p className='clwhite fs-14'>{SWAP_SETTING[from]}</p>
                                                <p className='txt-upper m-t-5 fs-10' style={{ color: theme.palette.textColor }}>{from}</p>
                                            </div>
                                        </div>
                                        <FaArrowRight className='fs-14 m-lr-10' />
                                        <div className='flex-m'>
                                            <img src={`/images/wallet/token-${to}.png`} alt="pay-token" style={{ width: 32, aspectRatio: 1 }} />
                                            <div className='flex-col-l m-l-10'>
                                                <p className='clwhite fs-14'>{SWAP_SETTING[to]}</p>
                                                <p className='txt-upper m-t-5 fs-10' style={{ color: theme.palette.textColor }}>{to}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Drawer>
            {/* review swap drawer */}
            <Drawer
                anchor="bottom"
                open={swapDrawerOpen}
                onClose={() => setSwapDrawerOpen(false)}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center' }}>
                    {/* estimation received */}
                    {
                        stepper != 5 ? // REVIEW STEP
                            <div>
                                <div className='flex-sb-m'>
                                    <p className='clwhite fs-16'>{t('swaps.reviewSwap')}</p>
                                    <MdClose className='pointer' style={{ width: 24, height: 24 }} onClick={() => setSwapDrawerOpen(false)} />
                                </div>
                                <div className='p-t-25'>
                                    <p className='fs-12 txt-left' style={{ color: theme.palette.textColor }}>{t('swaps.youPay')}</p>
                                    <div className='flex-sb-m p-t-20'>
                                        <p className='fs-30 clwhite txt-upper'>{currencyFormat(state.pay || 0, 2)} {firstPart}</p>
                                        <img src={`/images/wallet/token-${firstPart}.png`} alt="pay-token" style={{ width: 40, aspectRatio: 1 }} />
                                    </div>
                                </div>
                                <div className='p-t-20'>
                                    <p className='fs-12 txt-left' style={{ color: theme.palette.textColor }}>{t('swaps.youReceive')}</p>
                                    <div className='flex-sb-m p-t-20'>
                                        <p className='fs-30 clwhite txt-upper'>{currencyFormat(state.receive, 2)} {secondPart}</p>
                                        <img src={`/images/wallet/token-${secondPart}.png`} alt="pay-token" style={{ width: 40, aspectRatio: 1 }} />
                                    </div>
                                </div>
                            </div>
                            : // success
                            <div className='flex-col-c-m w-full'>
                                <img src='/images/general/successful-icon.png' alt='success-icon' style={{ width: 100, height: 100 }} />
                                <p className='p-t-30 clwhite txt-title fs-20'>{t('swaps.successfully')}</p>
                                <div className='flex-sa-m p-t-20'>
                                    <div className='flex-m p-l-10'>
                                        <img src={`/images/wallet/token-${firstPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                                        <p className='txt-upper clwhite fs-14 m-l-10'>{currencyFormat(state.pay, 2)} {firstPart}</p>
                                    </div>
                                    <FaArrowRight className='fs-14 m-lr-15' />
                                    <div className='flex-m'>
                                        <img src={`/images/wallet/token-${secondPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                                        <p className='txt-upper clwhite fs-14 m-l-10'>{currencyFormat(state.receive, 2)} {secondPart}</p>
                                    </div>
                                </div>
                                <p className='fs-14 p-t-30 pointer' style={{ color: theme.palette.primary.main }} onClick={() => window.open(explorerLink + state.txid, "_blank", "noreferrer")}>{t('swaps.viewExplorer')}</p>
                                <div className='p-t-30 w-full'>
                                    <Button className='w-full' style={{ color: theme.palette.primary.main, borderRadius: 16, minHeight: 56 }} onClick={() => setSwapDrawerOpen(false)} ><p className='flex-m txt-title fs-18'>{t('button.close')}</p></Button>
                                </div>
                            </div>
                    }
                    {/* stepper */}
                    <div>
                        {reviewSwapStepper(stepper)}
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));