import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import 'swiper/css/bundle';
import { storePaths } from '@utils/Tools';
import { LinearProgress, Typography, Tooltip, ClickAwayListener, Link, Dialog, Box, IconButton, Grid, DialogContentText, DialogTitle, Slide, } from '@mui/material';
import GameDrawer from '@layouts/GameDrawer';

import { FiEye, FiChevronRight, FiX } from "react-icons/fi";
import { HiMiniArrowUpCircle } from "react-icons/hi2";


const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});
    const [geneologyDialog, setGeneologyDialog] = useState(false);
    const { username } = useSelector(state => state.user);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [game, setGame] = useState([]);
    const [gameData, setGameData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getUrl(`/games`).then(response => {
            if (response.status) {
                setGame(response.data.games[0].games);
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     setLoading(true);
    //     getUrl('dashboard').then(response => {
    //         if (response.status && isMountedRef.current) {
    //             setData({ ...data, ...response.data });
    //         }
    //     }).catch(error => {
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     }).finally(() => {
    //         setLoading(false);
    //     });

    //     return () => isMountedRef.current = false;
    // }, []);

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            {/* Banner */}
            <div className='flex-c-m w-full'>
                <img src="/images/banner/banner1.png" alt="banner" className='w-full bor15' />
            </div>
            <div className='flex-c-m w-full'>
                <img src="/images/banner/banner3.png" alt="banner" className='w-full bor15' />
            </div>
            {/* Hot Games */}
            <div>
                <p className='flex-m txt-title clwhite'>{t('home.hotGames')}<img src='/images/general/hot.png' alt='hot-games' className='m-l-10 img-style' /></p>
                <div className='p-t-20 flex-l-m'>
                    <Swiper
                        slidesPerView={5.1}
                        navigation={true}
                        modules={[Navigation]}
                    // onSlideChange={(e) => ())}
                    >
                        {
                            _.map(game, (data, code) => {
                                return (
                                    <SwiperSlide key={code} className='flex-col-l pointer' onClick={() => { setDrawerOpen(true); setGameData({ ...data, code: code }) }}>
                                        <div className='bor10' style={{ border: '1px solid #303030', width: 60, height: 60 }}>
                                            <img src={`/images/game/${code}.png`} alt='game' />
                                        </div>
                                        <p className='clwhite fs-10 p-r-5 lh-12 p-t-10 txt_truncate2' style={{ fontWeight: 400, wordBreak: 'break-word' }}>{data.name}</p>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
            {/* Discover */}
            {/* <p className='clwhite'>{t('home.discover')}</p>
            <div className='flex-c-m w-full' style={{ height: 140, border: '1px solid #242424', borderRadius: 16 }}>
                <p className='clwhite'>Banner</p>
            </div> */}
            <GameDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} gameData={gameData} />
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
}));

export default Home;