import { Button, IconButton, InputAdornment, TextField, Typography, Container, Link } from '@mui/material';
import { useTheme } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { Link as RouterLink, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { storePaths } from '@utils/Tools';

import { useTranslation } from 'react-i18next';

import { FiEye, FiEyeOff, FiCheck } from 'react-icons/fi';

const Register = memo(() => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [searchParams] = useSearchParams();
    let location = useLocation();
    const [state, setState] = useState({
        username: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        referralName: searchParams.get('referral') ? searchParams.get('referral') : ''
    });
    let navigate = useNavigate();

    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
    });

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            username: state.username,
            email: state.email,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            referral_name: state.referralName
        }

        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setState({
                    ...state, 
                    username: '',
                    email: '',
                    password: '',
                    passwordConfirmation: '',
                    referralName: ''
                })
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Container>
            <div>
                <form>
                    <Helmet>
                        <meta name="description" content={process.env.REACT_APP_APP_NAME} />
                        <meta name="og:url" content={window.location.href} />
                        {/* <meta name="og:title" content={} /> */}
                        <meta property="og:type" content="website" />
                        <meta name="og:description" content={process.env.REACT_APP_APP_NAME} />
                        <meta property="og:image" content="images/logo192.png" />
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>
                    <div className='flex-col w-full fs-14 p-t-24' style={{ gap: 24, color: theme.palette.textColor }}>
                        <div>
                            <div className='flex-t'>
                                <p className='p-b-10'>{t('user.yourUsername')}</p>
                                <p className='cl-theme p-l-5'>*</p>
                            </div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='username'
                                placeholder={t('user.enterUsername')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                InputLabelProps={{ shrink: true }}
                                value={state?.username}
                            />
                        </div>
                        <div>
                            <div className='flex-t'>
                                <p className='p-b-10'>{t('user.yourEmail')}</p>
                                <p className='cl-theme p-l-5'>*</p>
                            </div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='email'
                                placeholder={t('user.enterEmail')}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                InputLabelProps={{ shrink: true }}
                                value={state?.email}
                            />
                        </div>
                        <div>
                            <div className='flex-t'>
                                <p className='p-b-10'>{t('user.yourPassword')}</p>
                                <p className='cl-theme p-l-5'>*</p>
                            </div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='password'
                                placeholder={t('user.enterPassword')}
                                value={state?.password}
                                onChange={handleChange}
                                type={option?.passwordShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('passwordShow')}>
                                                {option?.passwordShow ? <FiEye className="fs-icon" style={{ color: theme.palette.textColor }} /> : <FiEyeOff className="fs-icon" style={{ color: theme.palette.textColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div>
                            <div className='flex-t'>
                                <p className='p-b-10'>{t('user.confirmPassword')}</p>
                                <p className='cl-theme p-l-5'>*</p>
                            </div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='passwordConfirmation'
                                placeholder={t('user.confirmPassword')}
                                value={state?.passwordConfirmation}
                                onChange={handleChange}
                                type={option?.passwordConfirmationShow ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handlePasswordShow('passwordConfirmationShow')}>
                                                {option?.passwordConfirmationShow ? <FiEye className="fs-icon" style={{ color: theme.palette.textColor }} /> : <FiEyeOff className="fs-icon" style={{ color: theme.palette.textColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                                helperText={inputErrors && inputErrors.confirm_password ? inputErrors.confirm_password : ''}
                                error={inputErrors && inputErrors.confirm_password ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        {/* REFERRAL */}
                        <div>
                            <div className='flex-t'>
                                <p className='p-b-10'>{t('user.referralName')}</p>
                                <p className='cl-theme p-l-5'>*</p>
                            </div>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='referralName'
                                placeholder={t('user.referralName')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                error={inputErrors && inputErrors.referral_name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                value={state?.referralName}
                                disabled={searchParams.get("referral") ? true : false}
                            />
                        </div>
                        <Button variant="contained" onClick={handleSubmit} fullWidth ><p>{t('button.createAccount')}</p></Button>
                    </div>
                </form>
            </div>
        </Container>
    )
});

export default Register;