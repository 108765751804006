import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { bsc, bscTestnet } from 'viem/chains';
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store, persistor } from './configs/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Container } from '@mui/material';

import theme from './Theme';

import './i18n';

if (process.env.REACT_APP_NODE_ENV !== "development") {
	console.log = () => { };
}

const queryClient = new QueryClient();
const projectId = process.env.REACT_APP_WALLETCONNECT_ID;
const chain = process.env.REACT_APP_NODE_ENV == 'development' ? bscTestnet : bsc;

const Loading = () => {
	return (
		<div style={{ position: "fixed", top: '50%', left: '50%' }}>
			<CircularProgress disableShrink color='primary' />
		</div>
	);
};

const config = createConfig({
	autoConnect: false,
	chains: [chain],
	connectors: [
		injected(),
		walletConnect({ projectId }),
		metaMask(),
		safe(),
	],
	transports: {
		[chain.id]: http(),
	},
})

// <React.StrictMode> will render useEffect twice
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<React.StrictMode>
				<ThemeProvider theme={theme}>
					<Suspense fallback={<Loading />}>
						<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
							<CssBaseline />
							<WagmiProvider config={config}>
								<QueryClientProvider client={queryClient}>
									<Container maxWidth="xs" disableGutters style={{ background: theme.palette.backgroundColor, minHeight: '100vh', overflow: 'hidden' }}  >
										<App />
									</Container>
								</QueryClientProvider>
							</WagmiProvider>
						</BrowserRouter>
					</Suspense>
				</ThemeProvider>
			</React.StrictMode>
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
