import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FiAlertCircle, FiCheck, FiCopy, FiEye, FiEyeOff, FiX } from 'react-icons/fi';
import { currencyFormat, truncate } from '@utils/Tools';
import { FaArrowDown } from 'react-icons/fa';

export default function Deposit() {
    const { t } = useTranslation();
    const styles = useStyles();
    const { addAlert, loading, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const { accessToken } = useSelector(state => state.general);
    const { crypto_address, two_factor_confirmed_at } = useSelector(state => state.user);

    const [state, setState] = useState({
        address: [],
        rules: [],
        type: [],
    });
    const [tab, setTab] = useState('deposit');
    const [swapTypes, setSwapTypes] = useState([]);
    const [withdrawInfo, setWithdrawInfo] = useState({
        pay: '',
        receive: 0,
        swap: 'jfu-usdt',
        balances: [],
        securityPassword: '',
        authenticationCode: '',
        withdrawalRules: [],
    })
    const [firstPart, secondPart] = (withdrawInfo.swap).split('-');
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [withdrawalDrawerOpen, setWithdrawalDrawerOpen] = useState(false);
    const [withdrawStep, setWithdrawStep] = useState(1); // 1 review, 2 success
    const [inputErrors, setInputErrors] = useState([]);
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        let rate = swapTypes[withdrawInfo.swap]?.rate || 0;
        // both
        let value = withdrawInfo.pay * rate;
        value *= (100 - withdrawInfo.withdrawalRules?.fee_percent) / 100;
        value -= withdrawInfo.withdrawalRules?.fee_amount;
        setWithdrawInfo({ ...withdrawInfo, receive: value > 0 ? value : 0 });
        if (withdrawInfo.pay == '') {
            setInsufficientBalance(false);
        } else if ((Number(withdrawInfo.pay || 0) > Number(withdrawInfo.balances[firstPart] || 0)) || Number(withdrawInfo.pay || 0) < withdrawInfo.withdrawalRules?.min_amount) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
        // eslint-disable-next-line
    }, [withdrawInfo.swap, withdrawInfo.pay, swapTypes])

    useEffect(() => {
        setWithdrawInfo({ ...withdrawInfo, pay: '', receive: 0 });
    }, [tab])

    useEffect(() => {
        if (!withdrawalDrawerOpen) {
            setWithdrawStep(1);
        }
    }, [withdrawalDrawerOpen])


    useEffect(() => {
        setLoading(true);
        getUrl('/wallets/deposit_address').then(response => {
            if (response.status) {
                setState({ ...state, address: response.data.wallets.bicpay, rules: response.data.rules, type: response.data.type });
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (tab == 'withdraw') {
            setLoading(true);
            getUrl('/wallets/swap-setting').then(response => {
                if (response.status) {
                    setSwapTypes(response.data?.swap_setting);
                    setWithdrawInfo({
                        ...withdrawInfo,
                        balances: response.data?.balances,
                        withdrawalRules: response.data?.withdraw_rules
                    });
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [accessToken, refresh, tab])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'pay') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setWithdrawInfo({ ...withdrawInfo, [name]: value });
    };

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }
    }, []);

    const submitWithdrawal = () => {
        setLoading(true);
        setInputErrors([]);
        let params = {
            wallet_id: 1, // JFU, *backend hardcoded JFU
            amount: withdrawInfo.pay,
            security_password: withdrawInfo.securityPassword,
            authentication_code: withdrawInfo.authenticationCode
        }
        postUrl(`/wallets/withdrawal`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setWithdrawInfo({ ...withdrawInfo, pay: '', receive: 0, securityPassword: '', authenticationCode: '' });
                addAlert('', t('success.withdrawSuccess'), 'success', '');
                setRefresh(!refresh);
                setWithdrawStep(2);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.withdrawError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box style={{ marginTop: '110px', padding: '0 20px 150px' }}>
            {
                !loading ?
                    <div>
                        <div className='w-full flex-col fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                            <div className='flex-sa-m'>
                                <p className='w-full flex-c-m fs-16 txt-title p-tb-15 pointer' style={{ width: '50%', color: tab == 'deposit' ? '#FFFFFF' : theme.palette.textColor, borderBottom: tab == 'deposit' ? '2px solid ' + theme.palette.primary.main : 'none' }} onClick={() => setTab('deposit')}>{t('title.deposit')}</p>
                                <p className='w-full flex-c-m fs-16 txt-title p-tb-15 pointer' style={{ width: '50%', color: tab == 'withdraw' ? '#FFFFFF' : theme.palette.textColor, borderBottom: tab == 'withdraw' ? '2px solid ' + theme.palette.primary.main : 'none' }} onClick={() => setTab('withdraw')}>{t('title.withdraw')}</p>
                            </div>
                            {
                                tab === 'withdraw' ?
                                    <div className='p-t-20 flex-col-c'>
                                        <div className="w-full flex-col p-r-20 p-t-20 p-b-10" style={{ background: '#1B1B1B', borderRadius: 16, minHeight: 94, marginBottom: '-17px' }}>
                                            <div className='flex-sb-m p-l-20 fs-12' style={{ color: insufficientBalance ? '#c80000' : theme.palette.textColor }}>
                                                <p className='txt-title'>{t('swaps.pay')}</p>
                                                <p className='txt-title'>{t('swaps.balance')} {currencyFormat(withdrawInfo.balances[firstPart] || 0, 2)}</p>
                                            </div>
                                            <div className='w-full p-t-10'>
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    name='pay'
                                                    type="number"
                                                    placeholder="0"
                                                    onChange={handleChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    value={withdrawInfo.pay}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end" className='flex-col-m'>
                                                                <div className='flex-r-m'>
                                                                    <img src={`/images/wallet/token-${firstPart}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                                                                    <p className='txt-upper clwhite fs-14 m-l-5'>{firstPart}</p>
                                                                </div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{
                                                        input: {
                                                            fontSize: '30px',
                                                            color: insufficientBalance ? '#c80000' : '#ffffff'
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-c-m" style={{ zIndex: 3, background: '#1B1B1B', borderRadius: 6, border: '4px solid #121212', gap: 10, color: '#ffffff' }}><FaArrowDown className='fs-16 m-all-8' /></div>
                                        <div className="w-full flex-col p-all-20 m-b-8" style={{ background: '#1B1B1B', borderRadius: 16, minHeight: 94, marginTop: '-17px' }}>
                                            <div className='flex-sb-m fs-12' style={{ color: theme.palette.textColor }}>
                                                <p className='txt-title'>{t('swaps.get')}</p>
                                                <p className='txt-title'>{t('swaps.balance')} {currencyFormat(withdrawInfo.balances[secondPart] || 0, 2)}</p>
                                            </div>
                                            <div className='flex-sb-m p-t-20'>
                                                <p className='fs-30' style={{ color: withdrawInfo.receive > 0 ? '#FFFFFF' : '#5E5E5E' }}>{currencyFormat(withdrawInfo.receive || 0, 2)}</p>
                                                <div className='flex-m'>
                                                    <img src={`/images/wallet/token-${secondPart}.png`} alt="receive-token" style={{ width: 20, aspectRatio: 1 }} />
                                                    <p className='txt-upper clwhite fs-14 m-l-5'>{secondPart}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Button variant='contained' fullWidth disabled={insufficientBalance || !withdrawInfo.pay || state.pay <= 0} onClick={() => setWithdrawalDrawerOpen(true)}><p>{t('button.swapNWithdrawal')}</p></Button>
                                    </div>
                                    : // deposit
                                    <div className='w-full flex-col fs-14 p-t-20' style={{ gap: 24, color: theme.palette.textColor }}>
                                        <div>
                                            <div className='bgwhite p-all-5 bor5' style={{ width: 'fit-content', margin: '0 auto', lineHeight: 0 }}>
                                                <QRCodeSVG value={state.address?.['bsc']?.address} />
                                            </div>
                                            {/* <p className='fs-12 p-b-8 txt-center p-t-10'>{t('deposit.usdtDepositOnly')}</p> */}
                                        </div>
                                        <div>
                                            <div className='p-t-20'>
                                                <p>{t('deposit.network')}</p>
                                                <p className='m-t-5'><span className='clwhite'>BEP20</span> BNB Smart Chain (BSC)</p>
                                            </div>
                                            <div className='p-t-20'>
                                                <p>{t('deposit.address')}</p>
                                                <div className='flex-m w-full'>
                                                    <p className='clwhite p-r-15 m-t-5' style={{ width: 'calc(100% - 20px)', wordBreak: 'break-all' }}>{state.address?.['bsc']?.address}</p>
                                                    <FiCopy className='cl-theme fs-18 pointer' onClick={() => copyShareLink(state.address?.['bsc']?.address)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex-sb-m w-full p-t-20 p-b-10'>
                                                <p>{t('deposit.minDeposit')}</p>
                                                <div className='flex-sa-m clwhite'>
                                                    <p>{state.rules?.min_amount}</p>
                                                    <p className='m-l-4' style={{ color: theme.palette.textColor }}>{Object.keys(state.type).map(t => t.toUpperCase()).join(' / ')}</p>
                                                </div>
                                            </div>
                                            <div className='flex-sb-m w-full'>
                                                <p>{t('deposit.depositConfirmation')}</p>
                                                <p className='clwhite'>~ 1 min(s)</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex-m w-full bg-text p-all-16 m-b-10'>
                                                <FiAlertCircle className='fs-20' />
                                                <div className='p-l-10 fs-12 lh-14' style={{ width: 'calc(100% - 20px)' }}>
                                                    <p className='lh-13'>{t('swaps.depositDesc', { amount: state.rules?.min_amount, type: Object.keys(state.type).map(t => t.toUpperCase()).join(' / ') })}</p>
                                                </div>
                                            </div>
                                            <Button fullWidth variant='contained' onClick={() => copyShareLink(state.address?.['bsc']?.address)}><p>{t('button.copyAddress')}</p></Button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    : null
            }

            <Drawer
                anchor="bottom"
                open={withdrawalDrawerOpen}
                onClose={() => setWithdrawalDrawerOpen(false)}
            >
                <div className='p-b-40 p-lr-20' style={{ width: 'auto', maxHeight: '80vh', overflowY: 'auto' }}>
                    {/* review */}
                    {
                        withdrawStep == 1 ?
                            <div>
                                <div className='flex-sb-m p-tb-20' style={{ background: theme.palette.backgroundColor, color: theme.palette.textColor, position: 'sticky', top: 0, zIndex: 2000, }}>
                                    <div className='flex-m w-full clwhite'>
                                        <p className='w-full fs-16 txt-title'>{t('withdraw.review')}</p>
                                        <FiX className='fs-icon pointer' onClick={() => setWithdrawalDrawerOpen(false)} />
                                    </div>
                                </div>
                                <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                                    <div>
                                        <p className='fs-12 p-b-8'>{t('swaps.youPay')}</p>
                                        <div className='w-full flex-sb-m'>
                                            <p className='clwhite fs-21 txt-upper'><b>{currencyFormat(withdrawInfo.pay || 0, 2)} {firstPart}</b></p>
                                            <div style={{ width: 40 }}>
                                                <img src={`/images/wallet/token-${firstPart}.png`} alt="wallt" className='w-full' />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='fs-12 p-b-8'>{t('swaps.youReceive')}</p>
                                        <div className='w-full flex-sb-m'>
                                            <p className='clwhite fs-21 txt-upper'><b>{currencyFormat(withdrawInfo.receive || 0, 2)} {secondPart}</b></p>
                                            <div style={{ width: 40 }}>
                                                <img src={`/images/wallet/token-${secondPart}.png`} alt="wallt" className='w-full' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-tb-20'>
                                        <p>{t('withdraw.withdrawalAddress')}</p>
                                        <p className='clwhite w-full p-t-5' style={{ wordBreak: 'break-all' }}>{crypto_address}</p>
                                    </div>
                                    <div>
                                        <div className='flex-sb-m w-full'>
                                            <p>{t('swaps.rate')}</p>
                                            <p className='clwhite txt-upper'>1 {firstPart} = {swapTypes[withdrawInfo.swap]?.rate} {secondPart}</p>
                                        </div>
                                        <div className='flex-sb-m w-full p-t-15'>
                                            <p>{t('withdraw.fee')}</p>
                                            <p>{withdrawInfo.withdrawalRules?.fee_amount} USDT {withdrawInfo.withdrawalRules?.fee_percent && `+ ${withdrawInfo.withdrawalRules?.fee_percent}%`}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex-m w-full bg-text p-all-16 m-b-10'>
                                            <FiAlertCircle className='fs-20' />
                                            <div className='p-l-10 fs-12 lh-14' style={{ width: 'calc(100% - 20px)' }}>
                                                <p>{t('withdraw.desc')}</p>
                                            </div>
                                        </div>
                                        {
                                            !two_factor_confirmed_at ?
                                                <div className='p-tb-20'>
                                                    <p style={{ color: theme.palette.textColor }}>{t('user.securityPassword')}</p>
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        placeholder={t('user.securityPassword')}
                                                        type={showPassword?.securityPassword ? 'text' : 'password'}
                                                        value={withdrawInfo.securityPassword}
                                                        onChange={({ target }) => setWithdrawInfo({ ...withdrawInfo, securityPassword: target.value })}
                                                        helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                                        error={inputErrors && inputErrors.security_password ? true : false}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => handleClickShowPassword('securityPassword')}>
                                                                        {showPassword?.securityPassword ? <FiEye className="fs-icon" style={{ color: theme.palette.textColor }} /> : <FiEyeOff className="fs-icon" style={{ color: theme.palette.textColor }} />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            disableUnderline: true
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div className='p-tb-20'>
                                                    <p style={{ color: theme.palette.textColor }}>{t('user.2FA')}</p>
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        placeholder={t('user.2FA')}
                                                        type="text"
                                                        value={withdrawInfo.authenticationCode}
                                                        onChange={({ target }) => setWithdrawInfo({ ...withdrawInfo, authenticationCode: target.value })}
                                                        helperText={inputErrors && inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                                                        error={inputErrors && inputErrors.authentication_code ? true : false}
                                                    />
                                                </div>
                                        }
                                        <Button fullWidth className='pointer' variant='contained' onClick={submitWithdrawal}><p>{t('button.swapNWithdrawal')}</p></Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div> {/* success */}
                                <div className='p-b-40 p-t-80 txt-center'>
                                    <div className='flex-c-m bor48' style={{ width: 80, height: 80, margin: '0 auto', border: '1px solid', borderColor: theme.palette.primary.main }}>
                                        <FiCheck className='cl-theme fs-40' />
                                    </div>
                                    <p className='fs-18 p-t-20 clwhite lh-16'>{t('withdraw.success')}</p>
                                    {/* <p className='fs-14 lh-16' style={{ color: theme.palette.textColor }}>{t('withdraw.youSent')}  {truncate(crypto_address || '-', 16)}</p> */}
                                </div>
                                {/* <div className='p-tb-10 cl-theme pointer fs-14 txt-center m-b-40'>
                                    <p>{t('swaps.viewExplorer')}</p>
                                </div> */}
                                <Button variant='contained' fullWidth onClick={() => setWithdrawalDrawerOpen(false)}>
                                    <p className='p-r-10'>{t('button.close')}</p>
                                </Button>

                            </div>
                    }
                </div>
            </Drawer>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
}));