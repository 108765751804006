import { resetCodeState, resetProfile } from '../actions';
import { API } from '../configs/AxiosConfig';
import { store } from '../configs/Store';

export const removeLoginAccess = () => {
    store.dispatch(resetCodeState());
    store.dispatch(resetProfile());
}

const setHeader = () => {
    const accessToken = store.getState().general.accessToken;
    const language = store.getState().general.language;
    if(accessToken){
        API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }else{
        delete API.defaults.headers.common['Authorization'];
    }
    API.defaults.headers.common['Language'] = language === 'cn' ? 'cn' : 'en';
    API.defaults.headers.common['Site'] = 'Member';
    return true;
}

export const getUrl = async (url, param) => {
    setHeader();
    let result = [];
    await API.get(url, {params: param})
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const postUrl = async (url, postData) => {
    setHeader();
    let result = [];
    await API.post(url, postData)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const putUrl = async (url, putData) => {
    setHeader();
    let result = [];
    await API.put(url, putData)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const deleteUrl = async (url) => {
    setHeader();
    let result = [];
    await API.delete(url)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const loginGame = async (miniGameCode) => {
    postUrl('/game-login', { game_code: 'minigames', mini_game_code: miniGameCode}).then(response => {
        if (response.status) {
            let url = new URL(response.data.url);
            url.searchParams.set('token', atob(response.data.token));
            window.open(url.toString(), "_blank", "noreferrer");
        } else {
            alert(response.message);
        }
    }).catch((error) => {
        alert(error.message || "Please try again later or contact admin if problem persist.");
    });
}