import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// MUI
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FiEye, FiEyeOff, FiLock, FiMail } from "react-icons/fi";

import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

export default function ResetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const [searchParams] = useSearchParams();

    const [state, setState] = useState({
        email: '',
        username: '',
        password: '',
        passwordConfirmation: '',
        token: searchParams.get("token")
    });

    const [option, setOption] = useState({
        password_show: false,
        password_confirmation_show: false,
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();
    const [success, setSuccess] = useState(false);
    let navigate = useNavigate();

    const resetPassword = () => {
        setLoading(true);
        let params = {
            email: state.email,
            username: state.username,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            token: searchParams.get("token")
        }
        postUrl('/reset-password', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setSuccess(true);
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += ` ${value}`;
                })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        success === true ?
            <Box className="min-h-100vh pos-relative flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
                <div className='flex-c-m w-full' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/background/background.png")', backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
                    <img src="/logo_text.png" className='m-b-84' style={{ height: '100px', width: 'auto' }} alt="logo" />
                </div>
                <div className='textfield-border' style={{ marginTop: -47, }}>
                    <div className=' pos-relative textfield-bkg txt-white' >
                        <div className='p-t-50'>
                            <p className='fs-21'>{t('resetPassword.resetSuccess')}</p>
                            <p className='fs-14 p-t-5' style={{ color: theme.palette.textColor }}>{t('resetPassword.resetSuccessSubline')}</p>
                        </div>
                        <div className='p-t-30'>
                            <Button variant="contained" onClick={() => navigate("/login")} fullWidth ><p className='txt-upper' style={{ fontWeight: 600 }}>{t('general.backToLogin')}</p></Button>
                        </div>
                    </div>
                </div>
            </Box>
            :
            <Box className="min-h-100vh pos-relative flex-col flex-m" style={{ justifyContent: 'flex-start' }}>
                <div className='flex-c-m w-full' style={{ marginTop: 0, height: 300, backgroundImage: 'url("/images/background/background.png")', backgroundSize: 'cover', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
                    <img src="/logo_text.png" className='m-b-84' style={{ height: '100px', width: 'auto' }} alt="logo" />
                </div>
                {/* <center><img src="images/logos/logo_white_brand_full.png" style={{ height: 100, margin: '10px 0 20px' }} alt="logo" /></center> */}
                <div className='textfield-border' style={{ marginTop: -47, }}>
                    <div className=' pos-relative textfield-bkg txt-white' >
                        <div className='p-t-50'>
                            <p className='fs-21'>{t('resetPassword.title')}</p>
                            <p className='fs-14 p-t-5' style={{ color: theme.palette.textColor }}>{t('resetPassword.desc')}</p>
                        </div>
                        <div>
                            <div className='w-full'>
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.email')}
                                    fullWidth
                                    type="email"
                                    color='primary'
                                    value={state.email}
                                    onChange={({ target }) => setState({ ...state, email: target.value })}
                                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                    error={inputErrors && inputErrors.email ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.username')}
                                    fullWidth
                                    type="username"
                                    color='primary'
                                    value={state.username}
                                    onChange={({ target }) => setState({ ...state, username: target.value })}
                                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                    error={inputErrors && inputErrors.username ? true : false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiMail className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.newPassword')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handlePasswordShow('password_show')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {option?.password_show ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    color='primary'
                                    value={state.password}
                                    type={option?.password_show ? 'text' : 'password'}
                                    onChange={({ target }) => setState({ ...state, password: target.value })}
                                    helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                    error={inputErrors && inputErrors.password ? true : false}
                                />
                                <TextField
                                    variant="outlined"
                                    placeholder={t('user.confirmPassword')}
                                    type={option?.password_confirmation_show ? 'text' : 'password'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiLock className="img-style fs-18" style={{ color: '#ffffff' }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handlePasswordShow('password_confirmation_show')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {option?.password_confirmation_show ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    color='primary'
                                    value={state.passwordConfirmation}
                                    onChange={({ target }) => setState({ ...state, passwordConfirmation: target.value })}
                                    helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                    error={inputErrors && inputErrors.password_confirmation ? true : false}
                                />
                            </div>
                            <div className='p-t-30'>
                                <Button variant="contained" color="primary" size="large" onClick={resetPassword} fullWidth ><p className='txt-upper' style={{ fontWeight: 600 }}>{t('button.reset')}</p></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop100: {
        marginTop: 100,
        padding: '0px 10% 0px 10%'
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        letterSpacing: '-0.3px',
        lineHeight: '130%'
    },
    desc: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '125%',
    }
}));