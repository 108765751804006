import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useDispatch } from 'react-redux';
import { authFail, authSuccess } from '@actions';
import { reducePaths, storePaths } from '@utils/Tools';
import _ from 'lodash';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Connector, useConnect } from 'wagmi'
import { WalletConnect } from '@layouts/WalletConnect';

// MUI
import { Box, Button, Container, Drawer, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, Divider } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiEye, FiEyeOff, FiUser, FiLock } from 'react-icons/fi';

export default function Login() {
    const { t } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const { modal } = WalletConnect();

    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
    });
    const [inputErrors, setInputErrors] = useState({});

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                if (_.size(location?.state) > 0) {
                    let pathListing = reducePaths(location);
                    let path = _.size(pathListing) > 0 ? _.last(pathListing) : '/';
                    let urlLink = path?.pathname;
                    if (path?.search !== '') {
                        urlLink = urlLink + path?.search;
                    }
                    navigate(urlLink, { state: { from: pathListing } });
                } else {
                    navigate('/');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
            <Box component="form" onSubmit={loginUser} >
                <div className='flex-col w-full' style={{ gap: 24 }}>
                    <div>
                    <p style={{ color: theme.palette.textColor }}>{t('user.yourUsername')}</p>
                        <TextField
                            variant="standard"
                            fullWidth
                            placeholder={t('user.enterUsername')}
                            type="username"
                            autoComplete="username"
                            value={state.username}
                            onChange={({ target }) => setState({ ...state, username: target.value })}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                        />
                    </div>
                    <div>
                        <p style={{ color: theme.palette.textColor }}>{t('user.yourPassword')}</p>
                        <TextField
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <FiEye className="fs-icon" style={{ color: theme.palette.textColor }} /> : <FiEyeOff className="fs-icon" style={{ color: theme.palette.textColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            placeholder={t('user.enterPassword')}
                            type={state.showPassword ? 'text' : 'password'}
                            value={state.password}
                            onChange={({ target }) => setState({ ...state, password: target.value })}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                        />
                    </div>
                    <Button variant="contained" type="submit" fullWidth><p>{t('button.login')}</p></Button>
                    {/* <Link component={RouterLink} to={`/forgot-password`} underline="none" state={{ from: storePaths(location) }}>
                    <p>{t('login.forgotPassword')}</p>
                    </Link> */}
                    
                    
                </div>
            </Box>
    );
}