import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Divider, Drawer, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import GameDrawer from '@layouts/GameDrawer';
import { currencyFormat } from '@utils/Tools';
import { WalletConnect } from '@layouts/WalletConnect';

import { FaArrowRight, FaPlus } from 'react-icons/fa';
import { HiPlus } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import DoneIcon from '@mui/icons-material/Done';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Index = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { web_three, two_factor_confirmed_at } = useSelector(state => state.user);
    const { ABI, chain, chainId, provider, hexChainId } = WalletConnect();
    const { walletProvider } = useWeb3ModalProvider();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [game, setGame] = useState([]);
    const [gameData, setGameData] = useState([]);
    const [topupDrawer, setTopupDrawer] = useState({
        open: false,
        code: ''
    })
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [state, setState] = useState({
        pay: '',
        securityPassword: '',
        authenticationCode: '',
        walletId: '',
    })
    const [balance, setBalance] = useState({
        jfu: 0,
        jfg: 0,
    })
    const [inputErrors, setInputErrors] = useState([]);
    const [topupStepper, setTopupStepper] = useState(1);
    const [topupLoading, setTopupLoading] = useState({
        topup: false,
        topupRetry: false,
        topupLoading: true
    })
    const [counter, setCounter] = useState(1);
    const [contract, setContract] = useState([]);
    const [whitelist, setWhitelist] = useState(false);
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const [gameBalanceLoading, setGameBalanceLoading] = useState(false);

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        if (!topupDrawer.open) {
            setState({ ...state, pay: '', securityPassword: '', authenticationCode: '', txid: '' });
            setTopupStepper(1);
            // reset review
            setCounter(1);
            setTopupLoading({
                ...topupLoading,
                topup: false,
                topupRetry: false,
                topupLoading: true
            })
            gameBalance();
        }
        // eslint-disable-next-line
    }, [topupDrawer.open])

    useEffect(() => {
        setLoading(true);
        getUrl(`/games`).then(response => {
            if (response.status) {
                setGame(response.data.games[0].games);
                setContract(response.data.contract);
                setWhitelist(response.data.whitelist);
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const gameTopup = (txid = null) => {
        setLoading(true);
        setInputErrors([]);
        let params = {
            security_password: state.securityPassword,
            authentication_code: state.authenticationCode,
            amount: state.pay,
            wallet_id: (topupDrawer.code).toUpperCase(),
            game_code: 'minigames',
            txid: txid || '',
        }
        postUrl(`/game-topup`, params).then(response => {
            if (response.status) {
                setTopupStepper(4);
            } else {
                setInputErrors(response.errors);
                let message = '';
                if (_.size(response.errors) > 0) {
                    _.map(response.errors, (value, key) => {
                        message += "\n " + value[0];
                    })
                }
                addAlert('', message || response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const gameBalance = () => {
        setGameBalanceLoading(true);
        getUrl('/game-balance').then(response => {
            if (response.status) {
                setBalance({ ...balance, jfu: response.data?.balance_jfu || 0, jfg: response.data?.balance_jfg || 0 });
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setGameBalanceLoading(false);
        });
    }

    const web3Topup = async (contractAddress, abi) => {
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider) {
            // change chain if not connected to correct chain
            if (chain['id'] != chainId) {
                try {
                    await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                } catch (error) {
                    setTopupLoading({ ...topupLoading, topupLoading: false, topupRetry: true, topup: false });
                    addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                    return;
                }
            }
            try {
                let signer = await provider.getSigner();
                let contract = new ethers.Contract(contractAddress, abi, signer);
                let tx = await contract.gameTopup(ethers.parseUnits(state.pay));
                if (tx) {
                    setTopupLoading({ ...topupLoading, topupLoading: false, topup: true });
                    setTopupStepper(3); // wait confirmation
                }
                let receipt = await provider.waitForTransaction(tx.hash);
                if (receipt.status) {
                    gameTopup(tx.hash); // submit backend //! TODO: change to backend wait for transaction status so that frontend user no need to wait
                } else {
                    setTopupLoading({ ...topupLoading, topupLoading: false, topupRetry: true, topup: false });
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
                setTopupLoading({ ...topupLoading, topupLoading: false, topupRetry: false, topup: true });
            } catch (error) {
                setTopupLoading({ ...topupLoading, topupLoading: false, topupRetry: true, topup: false });
                const revertReason = error.reason || error.message || t('error.transactionError');
                addAlert('', revertReason + '. ' + t('error.contactSupport'), 'error', '');
            }
        } else {
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    const reviewStepper = (step) => {
        switch (step) {
            case 1: // input amount
                return (
                    <div>
                        <div className='flex-sb-m p-b-20'>
                            <p></p>
                            <p className='clwhite fs-16 p-l-20 txt-title'>{t('games.reload') + ' ' + (topupDrawer.code).toUpperCase() + ' (' + t('title.game') + ')'}</p>
                            <MdClose className='fs-24 pointer' onClick={() => setTopupDrawer({ ...topupDrawer, open: false, lebel: '' })} />
                        </div>
                        <div className='bg-text p-all-20' style={{ borderRadius: 16 }}>
                            <p className='fs-12 txt-left' style={{ color: theme.palette.textColor }}>{t('games.youAreReloading')}</p>
                            <div className='w-full p-t-60 p-b-40'>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='pay'
                                    type="number"
                                    placeholder="0"
                                    onChange={({ target }) => setState({ ...state, pay: target.value })}
                                    InputLabelProps={{ shrink: true }}
                                    value={state.pay}
                                    helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                                    error={inputErrors && inputErrors.amount ? true : false}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    FormHelperTextProps={{
                                        style: { textAlign: 'center', width: '100%' }
                                    }}
                                    sx={{
                                        input: {
                                            fontSize: '56px',
                                            textAlign: 'center',
                                            color: insufficientBalance ? '#c80000' : '#ffffff'
                                        }
                                    }}
                                />
                                <div className='p-t-20 flex-c-m'>
                                    <img src={`/images/wallet/token-${topupDrawer.code}.png`} alt="token" style={{ width: 20, height: 20 }} />
                                    <p className='p-l-10' style={{ color: theme.palette.textColor }}>{(topupDrawer.code).toUpperCase() + ' (' + t('title.game') + ')'}</p>
                                </div>
                            </div>
                        </div>
                        {
                            !web_three ?
                                <>
                                    {
                                        !two_factor_confirmed_at ?
                                            <div className='p-tb-20 txt-left'>
                                                <p style={{ color: theme.palette.textColor }}>{t('user.securityPassword')}</p>
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    placeholder={t('user.securityPassword')}
                                                    type={showPassword?.securityPassword ? 'text' : 'password'}
                                                    value={state.securityPassword}
                                                    onChange={({ target }) => setState({ ...state, securityPassword: target.value })}
                                                    helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                                    error={inputErrors && inputErrors.security_password ? true : false}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => handleClickShowPassword('securityPassword')}>
                                                                    {showPassword?.securityPassword ? <FiEye className="fs-icon" style={{ color: theme.palette.textColor }} /> : <FiEyeOff className="fs-icon" style={{ color: theme.palette.textColor }} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        disableUnderline: true
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='p-tb-20 txt-left'>
                                                <p style={{ color: theme.palette.textColor }}>{t('user.2FA')}</p>
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    placeholder={t('user.2FA')}
                                                    type="text"
                                                    value={state.authenticationCode}
                                                    onChange={({ target }) => setState({ ...state, authenticationCode: target.value })}
                                                    helperText={inputErrors && inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                                                    error={inputErrors && inputErrors.authentication_code ? true : false}
                                                />
                                            </div>
                                    }
                                </>
                                : null
                        }
                        <div className='p-t-10'>
                            <Button fullWidth variant='contained' style={{ padding: '16px 0 16px 0' }} disabled={insufficientBalance || state.pay <= 0} onClick={() => web_three ? setTopupStepper(2) : gameTopup()}><p className='fs-18'>{state.pay <= 0 ? t('games.enterAmount') : t('games.reload') + ' ' + (topupDrawer.code).toUpperCase() + ' (' + t('title.game') + ')'}</p></Button>
                        </div>
                    </div>
                );
            case 2: // sign msg
            case 3: // wait confirmation
                if (step == 2 && counter < 2) {
                    setCounter(counter + 1);
                    web3Topup(contract[topupDrawer.code], ABI[topupDrawer.code]);
                }
                return (
                    <div>
                        <div className='flex-sb-m'>
                            <p className='clwhite fs-16'>{t('games.confirmReload')}</p>
                            <MdClose className='pointer' style={{ width: 24, height: 24 }} onClick={() => setTopupDrawer({ ...topupDrawer, open: false, label: '' })} />
                        </div>
                        <div className='p-t-30'>
                            <p className='txt-left fs-12' style={{ color: theme.palette.textColor }}>{t('games.youAreReloading')}</p>
                            <div className='flex-sb-m p-t-15'>
                                <p className='fs-30 clwhite'>{currencyFormat(state.pay || 0, 2) + ' ' + (topupDrawer.code).toUpperCase()}</p>
                                <img src={`/images/wallet/token-${topupDrawer.code}.png`} alt='sign' style={{ width: 40, height: 40 }} />
                            </div>
                        </div>
                        <div className='flex-col fs-16 p-t-50' style={{ gap: 10 }}>
                            <div className='flex-sb-m'>
                                <div className='flex-l-m'>
                                    <div className='p-all-6' style={{ background: step == 2 ? theme.palette.primary.main : theme.palette.textColor, borderRadius: 100, height: 32, width: 32 }}>
                                        <img src='/images/general/sign-swap.png' alt='sign' style={{ width: 21, height: 21 }} />
                                    </div>
                                    <p className='m-l-10' style={{ color: step == 2 ? '#FFFFFF' : theme.palette.textColor }}>{t('swaps.signMsg')}</p>
                                </div>
                                <div>
                                    {
                                        topupLoading.topupLoading ?
                                            <CircularProgress style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                            :
                                            <>
                                                {
                                                    topupLoading.topup ?
                                                        <DoneIcon style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                                        :
                                                        <p className='pointer txt-upper' style={{ color: theme.palette.primary.main }} onClick={() => web3Topup(contract[topupDrawer.code], ABI[topupDrawer.code])}>{t('button.retry')}</p>

                                                }
                                            </>
                                    }
                                </div>
                            </div>
                            <Divider style={{ transform: 'rotate(90deg)', width: 16, color: theme.palette.textColor, border: `1px solid ${theme.palette.textColor}`, marginLeft: 7, marginTop: 8, marginBottom: 8 }} />
                            <div className='flex-sb-m'>
                                <div className='flex-l-m'>
                                    <div className='flex-c-m' style={{ background: step == 3 ? theme.palette.primary.main : theme.palette.textColor, borderRadius: 100, height: 32, width: 32 }}>
                                        {/* <img src='/images/general/confirm-reload.png' alt='reload' style={{ width: 24, height: 19 }} /> */}
                                        <HiPlus className='fs-24' style={{ color: '#121212' }} />
                                    </div>
                                    <p className='m-l-10' style={{ color: step == 3 ? '#FFFFFF' : theme.palette.textColor }}>{t('games.confirmReload')}</p>
                                </div>
                                {
                                    step == 3 &&
                                    <CircularProgress style={{ color: theme.palette.primary.main, width: 20, height: 20 }} />
                                }
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
            {
                (accessToken && whitelist) &&
                <div className='flex-sa-m' style={{ gap: 10 }}>
                    <div className='p-all-16 w-full' style={{ borderRadius: 16, border: `1px solid #242424`, color: theme.palette.textColor }}>
                        <div className='flex-m p-b-10'>
                            <img src="/images/wallet/token-jfg.png" alt="jfg" className='' style={{ height: 40, width: 40 }} />
                            <div className='p-l-10 fs-12'>
                                <p className='p-b-10'>JFG ({t('title.game')})</p>
                                <p className='fs-18 txt-white'>{gameBalanceLoading ? <CircularProgress className='m-l-5' style={{ width: 16, height: 16 }} /> : currencyFormat(balance.jfg, 2)}</p>
                            </div>
                        </div>
                        <Button variant='contained' style={{ padding: '8px 16px 8px 12px' }} onClick={() => setTopupDrawer({ ...topupDrawer, open: true, code: 'jfg' })}>
                            <HiPlus className='fs-16 cl-theme' />
                            <p className='cl-theme fs-12'>{t('games.reload')}</p>
                        </Button>
                    </div>
                    <div className='p-all-16 w-full' style={{ borderRadius: 16, border: `1px solid #242424`, color: theme.palette.textColor }}>
                        <div className='flex-m p-b-10'>
                            <img src="/images/wallet/token-jfu.png" alt="jfg" className='' style={{ height: 40, width: 40 }} />
                            <div className='p-l-10 fs-12'>
                                <p className='p-b-10'>JFU ({t('title.game')})</p>
                                <p className='fs-18 txt-white'>{gameBalanceLoading ? <CircularProgress className='m-l-5' style={{ width: 16, height: 16 }} /> : currencyFormat(balance.jfu, 2)}</p>
                            </div>
                        </div>
                        <Button disabled variant='contained' style={{ padding: '8px 16px 8px 12px' }} onClick={() => setTopupDrawer({ ...topupDrawer, open: true, code: 'jfu' })}>
                            <HiPlus className='fs-16 cl-theme' />
                            <p className='cl-theme fs-12'>{t('games.reload')}</p>
                        </Button>
                    </div>
                </div>
            }
            {
                _.map(game, (data, code) => {
                    return (
                        <div key={code} className='flex-sa-m pointer w-full' onClick={() => { setDrawerOpen(true); setGameData({ ...data, code: code }) }}>
                            <div className='bor10' style={{ border: '1px solid #303030', width: 60, height: 60 }}>
                                <img src={`/images/game/${code}.png`} alt='game' />
                            </div>
                            <div className='p-lr-10' style={{ width: 'calc(100% - 130px)' }}>
                                <p className='clwhite fs-16'>{data.name}</p>
                                <p className='fs-14 p-t-5 txt_truncate2' style={{ color: '#5E5E5E' }}>{data.descr[i18n.resolvedLanguage || 'en'] || '-'}</p>
                            </div>
                            <div className='p-lr-10'>
                                <Button disabled={!data.play} style={{ minWidth: 70 }}>{data.play ? t('games.play') : t('games.soon')}</Button>
                            </div>
                        </div>
                    )
                })
            }
            <GameDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} gameData={gameData} />
            {/* Game topup reload drawer*/}
            <Drawer
                anchor="bottom"
                open={topupDrawer.open}
                onClose={() => setTopupDrawer({ ...topupDrawer, open: false, lebel: '' })}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center', height: topupStepper == 1 ? '80vh' : '', overflowY: 'auto' }}>
                    {
                        topupStepper == 4 ?
                            <div className='flex-col-c-m w-full'>
                                <img src='/images/general/successful-icon.png' alt='success-icon' style={{ width: 100, height: 100 }} />
                                <p className='p-t-30 clwhite txt-title fs-20'>{t('success.reloadSuccess')}</p>
                                <p className='fs-14 p-t-30 pointer' style={{ color: theme.palette.textColor }}>{t('games.successMsg', { amount: state.pay, token: (topupDrawer.code).toUpperCase() })}</p>
                                <div className='p-t-30 w-full'>
                                    <Button className='w-full' style={{ color: theme.palette.primary.main, borderRadius: 16, minHeight: 56 }} onClick={() => setTopupDrawer({ ...topupDrawer, open: false, lebel: '' })} ><p className='flex-m txt-title fs-18'>{t('button.close')}</p></Button>
                                </div>
                            </div>
                            :
                            reviewStepper(topupStepper)

                    }
                </div>
            </Drawer>
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
}));

export default Index;
